<template>
  <h1>Einstellung</h1>
  <form @submit.prevent="save">
    <div class="form-row">
      <div class="col-8">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="name">{{ elementLabel }}</label>
          </div>
          <input v-model="form.value" class="form-control" id="name" placeholder="">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <button @click="closeForm" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button type="submit">Speichern</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "SettingEditor",
  data() {
    return {
      form: {
        value: null
      }
    }
  },
  props: {
    elementId: {
      type: Number,
      default: null
    },
    elementLabel: {
      type: String,
      required: true
    },
    elementValue: {
      type: String,
      required: false
    }
  },
  created() {
    this.form.value = this.elementValue
  },
  methods: {
    closeForm() {
      this.$emit('closeForm')
    },
    savedForm() {
      this.$emit('savedForm')
    },
    async save() {
      const response = await this.$store.dispatch('cruItem', {name: 'setting', id: this.elementId, data: this.form})
      // console.log('got response',response)
      if (response && response.success === true) {
        this.savedForm()
      }
    },
    loadData(entry) {
      this.form = entry
    }
  }
}
</script>

<style scoped>

</style>