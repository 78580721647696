<template>
  <form @submit="openCsv" :action="formLink" target="_blank" method="post">
    <input v-model="authorizationKey" type="hidden" name="authorization"/>
    <button class="blank">{{ label }}</button>
  </form>
</template>

<script>
export default {
  name: "ApiCsvLink",
  data() {
    return {
      formLink: ''
    }
  },
  props: {
    method: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'CSV',
      required: false
    }
  },
  computed: {
    authorizationKey() {
      return this.$store.getters.userToken +'.'+ this.$store.getters.userUid
    },
    entityId() {
      return this.$props.elementId
    }
  },
  methods: {
    openCsv() {
      this.formLink = process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
          + '/csv/' + this.$props.method + '/'

      if (this.$props.elementId)
        this.formLink += this.$props.elementId

      this.$emit('openedPdf', {method: this.method, elementId: this.elementId})
    }
  }
}
</script>

<style scoped>
  .opened {
    margin: -3px 4px 0 0;
  }
</style>