<template>
  <div
      v-if="workflowState === 0"
      class="w-100">
    <h2>Nassbehälter</h2>
    <div
        v-if="getWetVessels().length">
      <ul class="output">
        <li
            v-for="bag in getWetVessels()"
            :key="bag.vesselId">
          <label class="col-3">{{ bag.uniqueNumber }}</label> {{ parseInt(bag.weight) }} kg
          <button v-show="canWrite" @click="openDialog(
              { element: 'der Nassbehälter',nbr: bag.uniqueNumber, weight: bag.weight, uid: bag.uniqueId})"
                  class="del">-
          </button>
        </li>
      </ul>
    </div>
    <form v-show="canWrite" @submit.prevent="saveBag"
          class="row">
      <div class="input-group col-6">
        <div class="input-group-prepend">
          <label class="input-group-text" for="wetWeight">Nass</label>
        </div>
        <input v-model="wetWeight" type="number" class="form-control form-control-lg"
               id="wetWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <button class="col-2 mt-0 mb-0 pt-0 pb-0" type="submit" style="text-align: center">+</button>
    </form>
    <div
        v-if="getWetVessels().length">
      <h2>Nassgewicht</h2>
      <ul class="output">
        <li class="bold">
          <label class="col-2 bold">Gesamt</label> {{ totalWeight(1) }} kg
        </li>
      </ul>
      <h2>Notizen</h2>
      <form class="row">
        <div class="form-group col-8">
          <label for="exampleFormControlTextarea1">Qualität</label>
          <textarea v-model="noteQuality" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="col-1">
          <label for="wetWeight">Foto</label>
        </div>
        <div class="col-5">
          <input id="camera" ref="camera" @change="onFileChange" type="file"
                 style="display: none" accept="image/*" capture="camera"/>
          <img :src="noteImage" style="max-width: 100%;max-height: 300px"/>
        </div>
        <div v-show="canWrite" class="col-2">
          <button v-if="noteImage" @click="resetPhoto" class="del">-</button>
          <button v-else class="mt-0 mb-0 pt-0 pb-0" type="submit" @click="openCamera" style="text-align: center">+
          </button>
        </div>
        <!--        <input type="file" accept="image/*" capture="camera" id="customFile2"/>-->
      </form>
      <button v-show="canWrite" @click="finishWashing" type="button" class="done">Abschluss</button>
    </div>
  </div>
  <div
      v-else-if="workflowState === 1"
      class="w-100">

    <h2 class="mb-0">Trocknung</h2>
    <form v-show="canWrite" @submit.prevent="saveDried" class="row">
      <div v-if="dryersAmount > 1" class="input-group col-8 mt-3 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryerId">Trockner</label>
        </div>
        <select
            v-model="dryerId"
            class="custom-select custom-select-lg" id="dryerId"
        >
          <option
              v-for="index in dryersAmount"
              :key="index"
              :value="index">Trockner {{ index }}
          </option>
        </select>
      </div>
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedMoisture">Feuchtigkeit</label>
        </div>
        <input v-model="driedMoisture" type="number" step=".01" class="form-control form-control-lg"
               id="driedMoisture" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedHlWeight">HL-Gewicht</label>
        </div>
        <input v-model="driedHlWeight" type="number" step=".01" class="form-control form-control-lg"
               id="driedHlWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <div class="input-group col-4 w55">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedDate">D</label>
        </div>
        <input v-model="driedDate" class="form-control form-control-lg"
               id="driedDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
      </div>
      <div class="input-group col-4 w45">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driedTime">Z</label>
        </div>
        <input v-model="driedTime" class="form-control form-control-lg"
               id="driedTime" placeholder="HH:mm">
      </div>
      <div class="input-group form-check mt-3">
        <input v-model="dryerCleaned" type="checkbox" class="form-check" id="dryerCleaned">
        <label class="form-check-label ml-2" for="dryerCleaned">Trockner gereinigt</label>
      </div>
      <div v-if="dryingStored" class="col-8 mt-3 text-right">
        <img class="opened" src="@/assets/images/icon-done.svg"/>
        gespeichert
      </div>
      <button @click="storeDrying" class="mt-3 mb-3" type="button">Speichern</button>
    </form>

    <h2>Big Bags</h2>
    <div
        v-if="getDryVessels().length">
      <ul class="output">
        <li
            v-for="bag in getDryVessels()"
            :key="bag.vesselId">
          <button @click="openDialog(
              { element: 'der Big Bag',nbr: bag.uniqueNumber, weight: bag.weight, uid: bag.uniqueId})"
                  class="del">-
          </button>
          <label class="col-2">{{ bag.uniqueNumber }}</label> {{ parseInt(bag.weight) }} kg
          <br v-if="bag.regraterId != batch.regraterId"/>
          <label v-if="bag.regraterId != batch.regraterId" class="offset-1">&uarr; {{ regraterName(bag.regraterId) }}</label>
          <button @click="editBag(bag)" class="edit">~</button>
          <api-pdf-link method="bigbagslip"
                        @openedPdf="openedPdf"
                        :elementId="bag.uniqueId" :opened="parseInt(bag.openedBigBagSlip)" class="float-right"/>
        </li>
      </ul>
    </div>
    <form v-show="canWrite" @submit.prevent="saveBag"
          class="row">
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="vessel_regraterId">Aufkäufer</label>
        </div>
        <select
            v-model="vessel_regraterId" @change="getFillableVessels"
            class="custom-select custom-select-lg" id="vessel_regraterId">
          <option value="">Auswählen..</option>
          <option
              v-for="regrater in regraters"
              :key="regrater.contactId"
              :value="regrater.contactId">{{ regrater.name }}
          </option>
        </select>
      </div>
      <div class="input-group col-6">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryWeight">Trocken</label>
        </div>
        <input v-model="dryWeight" type="number" class="form-control form-control-lg"
               id="dryWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <button class="col-2 mt-0 mb-0 pt-0 pb-0" type="submit" style="text-align: center">+</button>
      <details class="col-8" :open="vessel_parentForm">
        <summary><h2>Big Bag auffüllen</h2></summary>
        <select
            v-model="parent_vesselId"
            class="custom-select custom-select-lg" id="parent_vesselId"
        >
          <option value="">Auswählen...</option>
          <option
              v-for="parent in fillableVessels"
              :key="parent.vesselId"
              :value="parent.uniqueId">{{ parent.uniqueNumber }} {{ parseInt(parent.weight) }} kg
          </option>
        </select>
      </details>
    </form>
    <div
        v-if="getDryVessels().length">
      <h2>Trockengewicht</h2>
      <ul class="output">
        <li class="bold">
          <label class="col-4 bold">Gesamt</label> {{ totalWeight(2) }} kg
        </li>
      </ul>
      <button @click="finishDrying" type="button" class="done">Abschluss</button>
    </div>
  </div>
  <div
      v-else-if="workflowState === 2 && getDryVessels().length" class="w-100">
    <h2>Big Bags</h2>
    <div
        v-if="getDryVessels().length">
      <ul class="output">
        <li
            v-for="bag in getDryVessels()"
            :key="bag.vesselId">
          <label class="col-3">{{ bag.uniqueNumber }}</label> {{ parseInt(bag.weight) }} kg
          <label v-if="getChildVesselsText(bag.vesselId)">+ {{ getChildVesselsText(bag.vesselId) }}</label>
          <br v-if="bag.regraterId != batch.regraterId"/>
          <label v-if="bag.regraterId != batch.regraterId">&uarr; {{ regraterName(bag.regraterId) }}</label>
          <api-pdf-link method="bigbagslip"
                        @openedPdf="openedPdf"
                        :elementId="bag.uniqueId" :opened="parseInt(bag.openedBigBagSlip)" class="float-right"/>
        </li>
      </ul>
    </div>
    <form @submit.prevent="saveBag"
          class="row">
    </form>
  </div>
  <app-modal-dialog v-if="modalOpened" @abortDialog="abortDialog" @confirmDialog="confirmDialog">
    <h1>Löschen bestätigen</h1>
    <div class="w-100">{{ modalText }}</div>
    <div class="w-100"><strong>{{ modalElement }}</strong></div>
  </app-modal-dialog>
  <popup-bag-editor v-if="bagEditorOpened" :element="bagElement" :regraters="regraters"
                    @abortDialog="abortBagEdit" @confirmDialog="saveBagEdit"></popup-bag-editor>
  <!--  <div-->
  <!--      v-else-if="workflowState === 1"-->
  <!--      class="row">-->
  <!--    <h2>Big Bags</h2>-->
  <!--    <form @submit.prevent="save" class="col-8">-->
  <!--      &lt;!&ndash;      <input name="formName" value="dry" type="hidden"/>&ndash;&gt;-->
  <!--      <div class="input-group mb-3">-->
  <!--        <div class="input-group-prepend">-->
  <!--          <label class="input-group-text" for="weight">Gewicht</label>-->
  <!--        </div>-->
  <!--        <input v-model="weight" type="number" class="form-control form-control-lg"-->
  <!--               id="weight" placeholder="">-->
  <!--        <div class="input-group-append">-->
  <!--          <span class="input-group-text">kg</span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <button type="submit">Hinzufügen</button>-->
  <!--    </form>-->
  <!--  </div>-->
</template>

<script>
import ApiPdfLink from "@/components/ApiPdfLink";
import AppModalDialog from "@/components/AppModalDialog";
import dayjs from "dayjs";
import PopupBagEditor from "@/components/PopupBagEditor";

export default {
  name: "BagList",
  components: {PopupBagEditor, AppModalDialog, ApiPdfLink},
  data() {
    return {
      activeBags: [...this.bags],
      weight: null,
      wetWeight: null,
      dryWeight: null,
      noteQuality: '',
      noteImage: '',
      modalOpened: false,
      modalText: '',
      modalElement: '',
      modalUid: '',
      dryerId: 1,
      driedMoisture: null,
      driedHlWeight: null,
      driedDate: null,
      driedTime: null,
      dryerCleaned: true,
      dryingStored: false,
      bagElement: null,
      bagEditorOpened: false,
      vessel_regraterId: '',
      vessel_parentForm: false,
      fillableVessels: [],
      parent_vesselId: ''
    }
  },
  props: {
    batch: {
      type: Object,
      required: true
    },
    bags: {
      type: Array,
      required: true
    },
    workflowState: {
      type: Number,
      required: false
    },
    canWrite: {
      type: Boolean,
      default: false
    },
    regraters: {
      type: Array,
      required: true
    }
  },
  async created() {
    const batch = this.$props.batch

    switch (this.workflowState) {
      case 1:
        if (batch.dryerId)
          this.dryerId = batch.dryerId
        if (batch.driedMoisture)
          this.driedMoisture = batch.driedMoisture
        if (batch.driedHlWeight)
          this.driedHlWeight = batch.driedHlWeight
        this.dryerCleaned = batch.dryerCleaned === null ? true : parseInt(batch.dryerCleaned) === 1
        if (batch.driedDateTime) {
          this.setDriedDateTime(batch.driedDateTime)
        } else
          this.setDriedDateTime()

        this.vessel_regraterId = batch.regraterId
        await this.getFillableVessels()
        break

      case 2:
        this.childVessels = await this.$store.dispatch('getItemsByParent', {
          entityName: 'vessel',
          method: 'childs',
          parentEntity: 'batch',
          parentId: batch.batchId,
          returnData: true
        })

        // console.log('childsByParent', this.childVessels)
        break
    }
  },
  computed: {
    dryersAmount() {
      return this.$store.getters.dryersAmount
    }
  },
  methods: {
    getWetVessels() {
      // console.log('getwetvessels',this.$props.bags)
      let bags = [];
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState == 1) {
          bags.push(this.$props.bags[idx])
        }
      }
      return bags
    },
    getDryVessels() {
      let bags = [];
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState >= 2) {
          bags.push(this.$props.bags[idx])
        }
      }
      return bags
    },
    async getFillableVessels() {
      this.fillableVessels = await this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel',
        method: 'fillable',
        parentEntity: 'regrater',
        parentId: this.vessel_regraterId,
        returnData: true
      })
    },
    getChildVesselsText(vesselId) {
      let texts = []
      for (let idx in this.childVessels) {
        if (this.childVessels[idx].parent_vesselId == vesselId) {
          texts.push(this.childVessels[idx].uniqueNumber + ' ' + parseInt(this.childVessels[idx].weight) + ' kg')
        }
      }

      if (texts.length > 0)
        return texts.join(', ')

      return null
    },
    async saveBag() {
      let bag = {}

      if (this.$props.workflowState == 0) {
        bag = {
          weight: this.wetWeight,
          workflowState: 1
        }
        this.wetWeight = ''
      } else { // workflowState === 1
        bag = {
          weight: this.dryWeight,
          workflowState: 2,
          regraterId: this.vessel_regraterId
        }
        this.dryWeight = ''
      }
      bag['batchId'] = this.batch.batchId
      bag['parent_vesselId'] = this.parent_vesselId

      const response = await this.$store.dispatch('cruItem',
          {name: 'vessel', data: bag, parentEntity: 'batch', parentId: this.batch.batchId})

      if (response && response.success === true) {
        if (this.$props.workflowState === 0)
          this.getWashed()
        else {
          this.getDried()
          this.getFillableVessels()
        }
      }

      if (this.getDryVessels().length === 1) {
        this.setDriedDateTime()
      }

      this.vessel_parentForm = false
      this.parent_vesselId = ''
      // this.$store.dispatch('createBag', {batchId: this.$props.batch.id, bag}).then(this.checkIfBagged.bind(this))
    },
    getWashed() {
      this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel', parentEntity: 'batch',
        parentId: this.batch.batchId, method: 'washed'
      })
    },
    getDried() {
      this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel', parentEntity: 'batch',
        parentId: this.batch.batchId, method: 'dried'
      })
    },

    totalWeight(workflowState) {
      let total = 0;
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState == workflowState) {
          total += parseInt(this.$props.bags[idx].weight)
        }
      }
      return total
    },
    async finishWashing() {
      const data = {
        washedWeight: this.totalWeight(1),
        washedQuality: this.noteQuality,
        washedImage: this.$refs.camera.files[0]
      }
      const response = await this.$store.dispatch('uploadItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.$props.batch.washedQuality = this.noteQuality
        this.$props.batch.washedImage = this.noteImage
        this.$props.batch.washedWeight = this.totalWeight(1)
      }
      // this.$props.workflowState = 1
    },
    saveDried() {
      // prevent-function to activate browser validation for input fields
    },
    async storeDrying() {
      this.dryingStored = false
      const data = {
        dryerId: this.dryerId,
        driedMoisture: this.driedMoisture,
        driedHlWeight: this.driedHlWeight,
        driedDate: this.driedDate,
        driedTime: this.driedTime,
        dryerCleaned: this.dryerCleaned
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.dryingStored = true
      }
    },
    async finishDrying() {
      const data = {
        dryerId: this.dryerId,
        driedMoisture: this.driedMoisture,
        driedHlWeight: this.driedHlWeight,
        driedDate: this.driedDate,
        driedTime: this.driedTime,
        dryerCleaned: this.dryerCleaned,
        driedWeight: this.totalWeight(2)
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.$props.batch.driedWeight = this.totalWeight(2)
      }
      // this.$props.batch.bagged = true
    },
    openedPdf(params) {
      if (params.method === 'bigbagslip') {
        const vessel = this.$props.bags.find(vessel => vessel.uniqueId == params.elementId)
        vessel.openedBigBagSlip = 1
      }
    },
    openDialog({element, nbr, weight, uid}) {
      this.modalText = 'Soll ' + element + ' gelöscht werden?'
      this.modalElement = nbr + ' mit ' + parseInt(weight) + ' kg'
      this.modalOpened = true
      this.modalUid = uid
    },
    abortDialog() {
      this.modalOpened = false
    },
    async confirmDialog() {
      this.modalOpened = false
      await this.$store.dispatch('delItem', {name: 'vessel', uid: this.modalUid})
      await this.$store.dispatch('getItemsByParent',
          {entityName: 'vessel', parentEntity: 'batch', parentId: this.batch.batchId})
    },
    checkIfBagged() {
      // let bagsWeight = 0
      //
      // for(let i in this.$props.batch.bags) {
      //   bagsWeight += this.$props.batch.bags[i].weight
      // }
      //
      // if (bagsWeight === this.$props.batch.dryWeight) {
      //   this.$props.batch.bagged = true
      // }
    },
    openCamera(e) {
      e.preventDefault()
      this.$refs.camera.click()
      // this.scrollToTop()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.noteImage = URL.createObjectURL(file);
    },
    resetPhoto(e) {
      e.preventDefault()
      this.noteImage = null
    },
    setDriedDateTime(datetime) {
      const customParseFormat = require('dayjs/plugin/customParseFormat')

      if (datetime) {
        dayjs.extend(customParseFormat)
        this.driedDate = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('DD.MM.YYYY')
        this.driedTime = dayjs(datetime + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').format('HH:mm')
      } else {
        this.driedDate = dayjs(datetime).format('DD.MM.YYYY')
        this.driedTime = dayjs().format('HH:mm')
      }
    },
    // big bag popup editor
    editBag(bag) {
      this.bagElement = bag
      this.bagEditorOpened = true
    },
    abortBagEdit() {
      this.bagElement = null
      this.bagEditorOpened = false
    },
    async saveBagEdit({id, weight, regraterId}) {
      const data = {
        weight, regraterId
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'vessel', id, data: data})

      if (response && response.success === true) {
        this.bagElement.weight = weight
        this.abortBagEdit()
        this.getDried()
      }
    },
    regraterName(contactId) {
      const regrater = this.$store.state.regrater.find(regrater => regrater.contactId == contactId)

      if (regrater) return regrater.name
      else return null
    }
  }
}
</script>

<style scoped>
.w55 {
  flex: 0 0 55%;
  max-width: 55%;
}

.w45 {
  flex: 0 0 45%;
  max-width: 45%;
}

details summary h2 {
  font-family: inherit;
  border-bottom-width: 1px;
}
</style>