<template>
  <div v-if="activeLoading.licensePlateNumber">
    <h1>LKW <span class="key heavy">{{ activeLoading.licensePlateNumber }}</span></h1>
    <div v-if="activeLoading.vessel.length">
      <h2>Ladeliste</h2>
      <ul class="list w-100">
        <li v-for="(vessel, idx) in getLoadedVessels()"
            :key="vessel.vesselId" class="row">
          <button v-if="!finishedLoading()" @click="openDialog(
              { element: 'der Big Bag',nbr: vessel.uniqueNumber, weight: vessel.weight, uid: vessel.uniqueId})"
                  class="del col-1">-</button>
          <div class="col-1">{{ idx+1 }}</div>
          <label :class="'col-'+(finishedLoading() ? 5 : 3)">{{ vessel.uniqueNumber }} {{ vessel.supplier }}</label>
          <div class="col-2 text-right">{{ getVesselWeight(vessel) }}</div>
<!--          <span v-if="getVesselChilds(vessel.vesselId)"><br/>{{ getVesselChildsText(vessel) }}</span>-->
<!--          <div v-if="finishedLoading()" class="col-3 text-right">-->
<!--            <api-pdf-link method="bigbagslip" :elementId="vessel.uniqueId" class="float-right"/>-->
<!--          </div>-->
        </li>
      </ul>
      <div class="row mb-3">
        <div :class="'col-'+(finishedLoading() ? 6 : 5)" class="bold">Summe</div>
        <div class="col-2 bold text-right" :class="!finishedLoading() && 'pr-0'">{{ totalWeight() }} kg</div>
      </div>
      <div v-if="finishedLoading()" class="row mb-3">
        <div class="col-8">
          <api-pdf-link method="loadingslip" label="Ladeliste PDF" :elementId="activeLoading.uniqueId" class="float-right"/>
        </div>
      </div>
    </div>
    <div v-if="!finishedLoading()">
      <h2>Big Bags</h2>
      <ul class="nav" style="margin:0 0 0.5rem 0">
        <li @click="showQrReader" class="qr">
          QR-Code
          <img src="@/assets/images/icon-qr-code.svg"/>
        </li>
      </ul>
      <form @submit.prevent="saveBagLoading" class="col-8">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="bag">Big Bag</label>
          </div>
          <select
              v-model="bag"
              class="custom-select custom-select-lg" id="bag">
            <option>Auswählen..</option>
            <option
                v-for="bag in this.loadableVessels"
                :key="bag.vesselId"
                :value="bag.vesselId">
              {{ bag.uniqueNumber }} | {{ parseInt(bag.weight) }} kg | {{ bag.supplier }}
            </option>
          </select>
        </div>
        <button type="submit" class="mt-1">Geladen</button>
      </form>
      <button @click="finishLoading">Abschluss</button>
    </div>
  </div>
  <div v-else-if="!finishedLoading()" class="row">
    <h1>Verladen</h1>
    <form @submit.prevent="save" class="col-8">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="truck">Kennzeichen</label>
        </div>
        <input v-model="truck" type="text" class="form-control form-control-lg" id="truck" placeholder="">
      </div>
      <button type="submit">Starten</button>
    </form>
  </div>
  <app-btn-back class="pt-2" routerLink="LoadingPage"/>
  <app-qr-reader v-if="qr" :doEmit="true" @qrRead="qrRead"/>
  <app-modal-dialog v-if="modalOpened" @abortDialog="abortDialog" @confirmDialog="confirmDialog">
    <h1>Entfernen bestätigen</h1>
    <div class="w-100">{{ modalText }}</div>
    <div class="w-100"><strong>{{ modalElement }}</strong></div>
  </app-modal-dialog>
</template>

<script>
import ApiPdfLink from "@/components/ApiPdfLink";
import AppModalDialog from "@/components/AppModalDialog";

export default {
  name: "LoadingShow",
  components: {AppModalDialog,ApiPdfLink},
  data() {
    return {
      loadingId: null,
      loadableVessels: null,
      activeLoading: {
        vessel: []
      },
      truck: '',
      bag: '',
      loadingDone: false,
      storedBags: [],
      qr: false,
      modalOpened: false,
      modalText: '',
      modalElement: '',
      modalUid: '',
    }
  },
  props: {
    id: {
      required: false,
      type: String
    }
  },
  async created() {
    if (this.id) {
      this.loadingId = this.id
      await this.getActiveLoading()
    }
    // this.$store.state.vessel = []
    await this.getLoadableVessels()
  },
  methods: {
    // vessels() {
    //   return this.$store.state.vessel
    // },
    totalWeight() {
      const vessels = this.activeLoading.vessel
      let total = 0

      for (let idx in vessels) {
        total += parseInt(vessels[idx]['weight'])
      }
      return this.formattedInt(total)
    },
    async save() {
      const data = {
        licensePlateNumber: this.truck
      }
      const response = await this.$store.dispatch('cruItem', {name: 'loading', data})
      // console.log('saved',response.id)
      this.loadingId = response.id
      this.activeLoading.licensePlateNumber = this.truck
    },
    async saveBagLoading() {
      const data = {
        loadingId: this.loadingId
      }
      await this.$store.dispatch('cruItem', {name: 'vessel', id: this.bag, data})
      await this.getActiveLoading()

      // const bagIndex = this.storedBags.findIndex(bag => bag.id === this.bag)
      // this.storedBags.splice(bagIndex, 1)
      await this.getLoadableVessels()
    },
    async finishLoading() {
      const data = {
        finishedLoading: 1
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'loading', id: this.loadingId, data})

      if (response && response.success) {
        this.activeLoading.finishedLoading = true
      }
      // this.$props.batch.bagged = true
    },
    finishedLoading() {
      // console.log('###',this.activeLoading.finishedLoading)
      if (this.activeLoading.finishedLoading == '0')
        return false

      return this.activeLoading.finishedLoading
    },
    async getActiveLoading() {
      this.activeLoading = await this.$store.dispatch('getItem', {name: 'loading', id: this.loadingId})
    },
    async getLoadableVessels() {
      this.loadableVessels = await this.$store.dispatch('getItemList', {entityName: 'vessel', method: 'loadable', returnData: true})
    },
    showQrReader() {
      this.qr = true
    },
    qrRead(params) {
      this.setBagId(params.qrString)
      this.saveBagLoading()
      this.qr = false
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    },
    setBagId(id) {
      const pos = id.indexOf('.')
      if (pos >= 0)
        id = id.substring(pos+1)
      if (id.indexOf('BB') === 0)
        id = id.substring(2)

      this.bag = id
    },
    openDialog({element, nbr, weight, uid}) {
      this.modalText = 'Soll '+ element +' aus der Ladeliste entfernt werden?'
      this.modalElement = nbr + ' mit '+ parseInt(weight) +' kg'
      this.modalOpened = true
      this.modalUid = uid
    },
    abortDialog() {
      this.modalOpened = false
    },
    async confirmDialog() {
      this.modalOpened = false
      this.bag = ''
      await this.$store.dispatch('delItem', {name:'loading', uid:this.modalUid})
      await this.getActiveLoading()
      await this.getLoadableVessels()
    },
    getLoadedVessels() {
      return this.activeLoading.vessel.filter(vessel => parseInt(vessel.parent_vesselId) === 0 || vessel.parent_vesselId == null)
    },
    getVesselWeight(vessel) {
      let weight = parseInt(vessel.weight)

      if (parseInt(vessel.parent_vesselId) > 0)
        return ''

      const childs = this.getVesselChilds(vessel.vesselId)
      if (childs) {
        for (const child of childs) {
          weight += parseInt(child.weight)
        }
      }

      return this.formattedInt(weight) + ' kg'
    },
    getVesselChilds(vesselId) {
      const childs = this.activeLoading.vessel.filter(vessel => vessel.parent_vesselId == vesselId)

      if (childs.length > 0)
        return childs

      return null
    },
    getVesselChildsText(vessel) {
      let texts = [
        vessel.uniqueNumber + ' ' + parseInt(vessel.weight) + ' kg'
      ]
      const childs = this.getVesselChilds(vessel.vesselId)

      for (const child of childs) {
        texts.push(child['uniqueNumber'] + ' ' + parseInt(child.weight) + ' kg')
      }

      return texts.join(', ')
    }
  }
}
</script>

<style scoped>

</style>