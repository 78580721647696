<template>
  <form @submit="openedPdf" :action="formLink" target="_blank" method="post">
    <input v-model="authorizationKey" type="hidden" name="authorization"/>
    <img class="opened" v-if="opened" src="@/assets/images/icon-done.svg"/>
    <button
        v-show="emailPrint"
        @click.prevent="printByEmail"
        class="blank" style="margin-right:0.5rem">Druck</button>
    <button class="blank">{{ label }}</button>
  </form>
</template>

<script>
export default {
  name: "ApiPdfLink",
  data() {
    return {
      formLink: ''
    }
  },
  props: {
    method: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'PDF',
      required: false
    },
    opened: {
      type: Number,
      default: 0,
      required: false
    }
  },
  async created() {
    // this.$store.dispatch('initItems', {entityName: 'setting'})
  },
  computed: {
    authorizationKey() {
      return this.$store.getters.userToken +'.'+ this.$store.getters.userUid
    },
    // userToken() {
    //   return this.$store.getters.userToken
    // },
    // userId() {
    //   return this.$store.getters.userId
    // },
    entityId() {
      return this.$props.elementId
    },
    emailPrint() {
      return this.$store.getters.printerEmail
      // const setting = this.$store.state.setting.find(item => item.settingsKey === 'printer_email')
      // if (!setting) return null
      //
      // return this.$store.state.setting.find(item => item.settingsKey === 'printer_email').value
    }
  },
  methods: {
    openedPdf() {
      this.formLink = process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
          + '/pdf/' + this.$props.method + '/' + this.$props.elementId

      this.$emit('openedPdf', {method: this.method, elementId: this.elementId})
    },
    async printByEmail() {
      await this.$store.dispatch('sendEmailToPrinter',{type: this.method, uid: this.elementId})
      this.$emit('openedPdf', {method: this.method, elementId: this.elementId})
    }
  }
}
</script>

<style scoped>
  .opened {
    margin: -3px 4px 0 0;
  }
</style>