<template>
  <dialog id="modalBagEditor" open>
    <h1>Charge {{ element.uniqueNumber }}</h1>
    <form class="row">

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="supplierId">Lieferant</label>
        </div>
        <select
            v-model="supplierId"
            @change="supplierChanged"
            class="custom-select custom-select-lg" id="supplierId"
            required>
          <option>Auswählen..</option>
          <option
              v-for="supplier in suppliers"
              :key="supplier.contactId"
              :value="supplier.contactId">{{ supplier.name }}, {{ supplier.city }}
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="qualityStandard">Standard</label>
        </div>
        <select
            v-model="qualityStandard"
            class="custom-select custom-select-lg" id="qualityStandard"
        >
          <option>Auswählen..</option>
          <option
              v-for="qualityStandard in qualityStandards"
              :key="qualityStandard.id"
              :value="qualityStandard.id">{{ qualityStandard.name }}
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="regraterId">Aufkäufer</label>
        </div>
        <select
            v-model="regraterId"
            class="custom-select custom-select-lg" id="regraterId">
          <option>Auswählen..</option>
          <option
              v-for="regrater in regraters"
              :key="regrater.contactId"
              :value="regrater.contactId">{{ regrater.name }}
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="batchDate">Datum</label>
        </div>
        <input v-model="batchDate" class="form-control form-control-lg"
               id="batchDate" placeholder="TT.MM.JJJJ" pattern="\d{1,2}.\d{1,2}.\d{4}">
      </div>

    </form>
    <div class="row mt-1 buttons">
      <div class="col-3">
        <button @click="abortDialog" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button @click="confirmDialog" type="submit">Speichern</button>
      </div>
    </div>
  </dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "PopupBatchEditor",
  data() {
    return {
      supplierId: null,
      qualityStandard: null,
      regraterId: null,
      batchDate: null
    }
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    suppliers: {
      type: Array,
      required: true
    },
    qualityStandards: {
      type: Array,
      required: true
    },
    regraters: {
      type: Array,
      required: true
    }
  },
  created() {
    this.supplierId = this.$props.element.supplierId
    this.regraterId = this.$props.element.regraterId
    this.qualityStandard = this.$props.element.qualityStandardId
    if (this.$props.element.batchDate)
      this.batchDate = dayjs(this.$props.element.batchDate).format('DD.MM.YYYY')
  },
  methods: {
    abortDialog() {
      this.$emit('abortDialog')
    },
    confirmDialog() {
      this.$emit('confirmDialog', {
        id: this.$props.element.batchId,
        supplierId: this.supplierId,
        regraterId: this.regraterId,
        qualityStandard: this.qualityStandard,
        batchDate: this.batchDate
      })
    }
  }
}
</script>

<style scoped>
dialog {
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  border: 0;
  padding: 20vh 1.5rem 0;
}

dialog .buttons div:first-of-type {
  padding-left: 0;
}

dialog .buttons div:last-child {
  padding-right: 0;
}
</style>