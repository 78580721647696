<template>
  <div v-if="showList">
    <h1>Einstellungen</h1>
    <table class="table table-sm table-hover clickable">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Einstellung</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="entry in getListItems()"
          :key="entry.settingId"
          @click="cruElement({id:entry.settingId,label:entry.label,value:entry.value})">
        <th scope="row">{{ entry.label }}</th>
        <td>{{ getSettingValue(entry) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <setting-editor v-if="formOpened"
                  :element-id="elementId"
                  :element-label="elementLabel"
                  :element-value="elementValue"
                  @closeForm="closeForm"
                  @savedForm="savedForm"
  />
  <app-btn-back class="pt-2"/>
</template>

<script>
import SettingEditor from "@/components/SettingEditor";

export default {
  name: "SettingPage",
  components: {SettingEditor},
  data() {
    return {
      showList: true,
      formOpened: false,
      elementId: null,
      elementLabel: '',
      elementValue: null,
      list: null
    }
  },
  created() {
    this.$store.dispatch('getItemList', {entityName: 'setting'})
  },
  methods: {
    getListItems() {
      return this.$store.state.setting
    },
    getSettingValue(entry) {
      switch (entry.valueType) {
        case 'i':
        case 's':
          return entry.value
        default:
          return entry.value == '1' ? 'Ja' : entry.value
      }
    },
    cruElement({id, label, value}) {
      this.elementId = id
      this.elementLabel = label
      this.elementValue = value
      this.showList = false
      this.formOpened = true
    },
    openForm() {
      this.showList = false
      this.formOpened = true
    },
    closeForm() {
      this.formOpened = false
      this.showList = true
      this.elementId = null
    },
    savedForm() {
      this.$store.dispatch('getItemList', {entityName: 'setting'})
      this.closeForm()
    }
  }
}
</script>

<style scoped>

</style>