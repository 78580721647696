<template>
  <dialog id="modalBagEditor" open>
    <h1>Big Bag {{ element.uniqueNumber }}</h1>
    <form class="row">
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryWeight">Trocken</label>
        </div>
        <input v-model="weight" type="number" step="1" class="form-control form-control-lg"
               id="dryWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <div class="input-group col-8 mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="regraterId">Aufkäufer</label>
        </div>
        <select
            v-model="regraterId"
            class="custom-select custom-select-lg" id="regraterId">
          <option value=""></option>
          <option
              v-for="regrater in regraters"
              :key="regrater.contactId"
              :value="regrater.contactId">{{ regrater.name }}
          </option>
        </select>
      </div>
<!--      <div class="input-group col-8 mb-3">-->
<!--        <div class="input-group-prepend">-->
<!--          <label class="input-group-text" for="regraterId">Aufgefüllt in</label>-->
<!--        </div>-->
<!--        <select-->
<!--            v-model="parent_vesselId"-->
<!--            class="custom-select custom-select-lg" id="parent_vesselId"-->
<!--        >-->
<!--          <option value=""></option>-->
<!--          <option-->
<!--              v-for="parent in fillableVessels"-->
<!--              :key="parent.vesselId"-->
<!--              :value="parent.uniqueId">{{ parent.uniqueNumber }} {{ parseInt(parent.weight) }} kg-->
<!--          </option>-->
<!--        </select>-->
<!--      </div>-->
    </form>
    <div class="row mt-5 buttons">
      <div class="col-3">
        <button @click="abortDialog" type="reset">Abbrechen</button>
      </div>
      <div class="col-5">
        <button @click="confirmDialog" type="submit">Speichern</button>
      </div>
    </div>
  </dialog>
</template>

<script>
export default {
  name: "PopupBagEditor",
  data() {
    return {
      weight: null,
      regraterId: null,
      // fillableVessels: []
    }
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    regraters: {
      type: Array,
      required: true
    }
  },
  created() {
    this.weight = parseInt(this.$props.element.weight)
    this.regraterId = this.$props.element.regraterId

    // load fillable vessels and include parent vessel

  },
  methods: {
    abortDialog() {
      this.$emit('abortDialog')
    },
    confirmDialog() {
      this.$emit('confirmDialog', {
        id: this.$props.element.vesselId,
        weight: this.weight,
        regraterId: this.regraterId
      })
    }
  }
}
</script>

<style scoped>
dialog {
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  border: 0;
  padding: 20vh 1.5rem 0;
}

dialog .buttons div:first-of-type {
  padding-left: 0;
}

dialog .buttons div:last-child {
  padding-right: 0;
}
</style>