export default {
    pageTitle: state => state.title,
    authUser: state => state.authId != null,
    userId: state => state.userId,
    contactName: state => state.contactNames,
    userUid: state => state.userUid,
    userToken: state => state.userToken,
    isAuthenticated: state => !!state.userToken,
    isAdmin: state => state.userPermission > 0 && state.userPermission <= 2,
    canWrite: state => state.userPermission > 0 && state.userPermission <= 3,
    isIntern: state => state.userRole <= 1,
    // settings
    dryersAmount: state => state.appSetting ? parseInt(state.appSetting.dryersAmount) : 1,
    printerEmail: state => state.appSetting ? state.appSetting.printerEmail : ''
}