<template>
  <header class="row">
    <div class="col-8">
      <router-link :to="{name:'Home'}"><img class="logo" src="@/assets/images/pit-story-logo.svg" alt=""></router-link>
      <img class="titelbild" src="@/assets/images/header-pit-256.jpg" alt="">
    </div>
    <div class="col-6">{{ pageTitle }}</div>
    <div class="col-2"></div>
  </header>
  <app-btn-back v-show="showBtnBack" class="top"/>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "TheHeader",
  // data() {
  //   return {
  //     showBtnBack: false
  //   }
  // },
  // created() {
  //   this.showBtnBack = this.pageTitle !== ''
  //   console.log(this.pageTitle)
  // },
  computed: {
    ...mapGetters(['pageTitle']),
    showBtnBack() {
      return this.pageTitle !== '' && this.pageTitle !== undefined
    }
  }
}
</script>

<style scoped>

</style>